@font-face {
  font-family: 'LowdragOutline-Extended';
  src: url(../../components/assets/fonts/LowdragOutline-Extended.woff2)
    format('woff2');
}
@font-face {
  font-family: 'LowdragOutline-Narrow';
  src: url(../../components/assets/fonts/LowdragOutline-Narrow.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Lowdrag-Narrow';
  src: url(../../components/assets/fonts/Lowdrag-Narrow.woff2) format('woff2');
}
@font-face {
  font-family: 'GrandSlang-B-Side';
  src: url(../../components/assets/fonts/GrandSlang-B-Side.woff2)
    format('woff2');
}
@font-face {
  font-family: 'GrandSlang-Italic';
  src: url(../../components/assets/fonts/GrandSlang-Italic.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Reforma1969-Gris';
  src: url(../../components/assets/fonts/Reforma1969-Gris.woff2) format('woff2');
}
@font-face {
  font-family: 'Reforma1969-NegraItalica';
  src: url(../../components/assets/fonts/Reforma1969-NegraItalica.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Reforma1918-Negra';
  src: url(../../components/assets/fonts/Reforma1918-Negra.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Panama-Bold';
  src: url(../../components/assets/fonts/Panama-Bold.woff) format('woff');
}
@font-face {
  font-family: 'TTTrailers-DemiBold';
  src: url(../../components/assets/fonts/TTTrailers-DemiBold.woff2)
    format('woff2');
}
@font-face {
  font-family: 'GTAmerica';
  src: url(../../components/assets/fonts/GTAmerica-Light.woff2) format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'GTAmerica';
  src: url(../../components/assets/fonts/GTAmerica-Regular.woff2)
    format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'GTAmerica';
  src: url(../../components/assets/fonts/GTAmerica-Medium.woff2) format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'GTAmerica-Compressed';
  src: url(../../components/assets/fonts/GTAmerica-CompressedLight.woff2)
    format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'GTAmerica-Compressed';
  src: url(../../components/assets/fonts/GTAmerica-CompressedRegular.woff2)
    format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'GTAmerica-Compressed';
  src: url(../../components/assets/fonts/GTAmerica-CompressedMedium.woff2)
    format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Vorsik-Regular';
  src: url(../../components/assets/fonts/Vorsik-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Pistilli';
  src: url(../../components/assets/fonts/Pistilli-Roman.otf) format('otf');
}
@font-face {
  font-family: 'Pistilli';
  src: url(../../components/assets/fonts/Pistilli-Roman.woff2) format('woff2');
}
@font-face {
  font-family: 'Cochin';
  src: url(../../components/assets/fonts/Cochin.woff2) format('woff2');
}
@font-face {
  font-family: 'MinionPro';
  src: url(../../components/assets/fonts/MinionPro-Regular.otf) format('otf');
}
@font-face {
  font-family: 'MinionPro';
  src: url(../../components/assets/fonts/MinionPro-Regular.woff2)
    format('woff2');
}

/* ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
} */

*[tabIndex^='-1']:focus {
  outline: none;
}

html,
body,
#root,
#root > div {
  font-family: 'Work Sans Regular', sans-serif;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* a:hover,
a:hover > *:not([class*='touchAction']) {
  text-decoration: underline;
} */

input:focus {
  outline: none !important;
}
